import rootReducer from './reducers'
import { createWrapper } from 'next-redux-wrapper'
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

const middlewares = [thunkMiddleware]
// if (!isProd) middlewares.push(logger)

const composeEnhancers =
    (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

const makeStore = () =>
    createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))

export const wrapper = createWrapper(makeStore, {
    debug: process.env.NODE_ENV !== 'production' ? false : false,
})

export default makeStore
