import getOldUsersListenData from './getOldUsersListenData'
import setUsersListenData from './setUsersListenData'
import isNumber from 'lodash/isNumber'

/**
 * Set Users Content Listen Data to Local Storage
 * @param {number} contentPlayed Number of unique content user has listened to
 * @param {number} secondsListened Number of seconds users has listened to
 * @param {string} lastActive When the user was last active
 * @param {string} lastAdsCall When the last ADS-Config api was called
 * @param {number} currentPlaytime Users current content playtime
 */

const setUsersListenDataToLC = () =>
    // contentPlayed = 0,
    // secondsListened = 0,
    // lastActive = new Date(),
    // lastAdsCall = new Date(), // might not be used
    // currentPlaytime = 0,
    // lastListened = null,
    // initialize = false
    {
        // Existing data in localstorage
        const usersListenData = getOldUsersListenData()

        // Default Users Listen Data
        const defaultUsersListenData = {
            audiobook: {
                contentPlayed: 0,
                secondsListened: 0,
                currentPlaytime: 0,
                lastListened: null,
            },
            radio: {
                contentPlayed: 0,
                secondsListened: 0,
                currentPlaytime: 0,
                lastListened: null,
            },
            audioseries: {
                contentPlayed: 0,
                secondsListened: 0,
                currentPlaytime: 0,
                lastListened: null,
            },
            podcast: {
                contentPlayed: 0,
                secondsListened: 0,
                currentPlaytime: 0,
                lastListened: null,
            },
        }

        // If there is no existing data OR
        // The present data is not valid, then
        // Reset The Data
        if (
            !usersListenData
            // !isNumber(usersListenData?.contentPlayed) ||
            // !isNumber(usersListenData?.secondsListened) ||
            // !isNumber(usersListenData?.currentPlaytime)
        ) {
            setUsersListenData({}, defaultUsersListenData)

            return
        }

        const updatedUserData = { ...usersListenData }

        for (const key in usersListenData) {
            const currentVerticalData = usersListenData[key]

            if (
                !isNumber(currentVerticalData?.contentPlayed) ||
                !isNumber(currentVerticalData?.secondsListened) ||
                !isNumber(currentVerticalData?.currentPlaytime)
            ) {
                setUsersListenData({}, defaultUsersListenData)
                break
            }

            // If there is previous current listen data add it to secondsListened
            if (currentVerticalData.currentPlaytime > 0) {
                updatedUserData[key] = {
                    ...currentVerticalData,
                    currentPlaytime: 0,
                    secondsListened:
                        currentVerticalData.secondsListened +
                        currentVerticalData.currentPlaytime,
                }
            }
        }

        setUsersListenData({}, updatedUserData)
    }

export default setUsersListenDataToLC
