import pino from 'pino'

const pinoLogger = pino()

export const logger = ({ url, response_status, responseTime, message }) => {
    const child = pinoLogger.child({ url, response_status, responseTime })

    if (message) child.error(message)
    else child.info(message)
}
