/**
 * Checks if passed str is a valid JSON string
 * @param {string | null} str
 * @returns {bool}
 */
export const isJSON = (str) => {
    try {
        const obj = JSON.parse(str)

        if (obj && typeof obj === 'object' && obj !== null) {
            return true
        }
    } catch (err) {
        console.log('json parsing error', err)
    }

    return false
}
