const setUsersListenData = (oldData, newData) => {
    return localStorage.setItem(
        'usersListenData',
        JSON.stringify({
            ...oldData,
            ...newData,
        })
    )
}

export default setUsersListenData
