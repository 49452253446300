import { isJSON } from '@/helpers/isJSON'

const getOldUsersListenData = () => {
    const usersListenData = localStorage.getItem('usersListenData')
    return isJSON(usersListenData)
        ? JSON.parse(localStorage.getItem('usersListenData'))
        : null
}

export default getOldUsersListenData
