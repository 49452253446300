import '../components/Layouts/styles.css'
import { AuthLayer } from '@/components/AuthLayer'
import IMAProvider from '@/components/Layouts/IMAProvider'
import setUsersListenDataToLC from '@/helpers/setUsersListenDataToLC'
import { wrapper } from '@/store'
import { initializeIma } from '@/store/actions/ima'
import '@/styles/globals.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { SessionProvider } from 'next-auth/react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import posthog from 'posthog-js'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import 'react-spring-bottom-sheet/dist/style.css'

const Layout = dynamic(() => import('@/components/Layouts/layout'))
const BaseLayout = dynamic(() => import('@/components/Layouts'))
const NextNProgress = dynamic(() => import('nextjs-progressbar'))

if (typeof window !== 'undefined') {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY || '', {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
        opt_in_site_apps: true,
        autocapture: false,
        capture_pageview: false,
    })
}

function NoiceApp({ Component, pageProps: { session, ...pageProps } }) {
    const dispatch = useDispatch()
    const [imaScriptLoaded, setImaScriptLoaded] = useState(false)
    const router = useRouter()

    const isNotLoggedIn =
        router.pathname.startsWith('/user-registration') ||
        router.pathname.startsWith('/otp') ||
        router.pathname.startsWith('/edit-profile')

    const canonicalUrl = (
        process.env.NEXT_PUBLIC_SITE_URL +
        (router.asPath === '/'
            ? ''
            : router.asPath === '/segment/b97aad56-0596-4694-a155-8b64491e4e2a'
            ? '/livestream-list'
            : router.asPath)
    ).split('?')[0]

    useEffect(() => {
        // Setup Local Storage with users listen data for preroll/midroll ads
        setUsersListenDataToLC()
    }, [])

    useEffect(() => {
        if (!imaScriptLoaded) return
        // Setup IMA
        const adContainer = document.querySelector('.ad-container')
        const adAudioContent = document.querySelector('.ad-audio')

        try {
            // eslint-disable-next-line no-undef
            const adsDisplayContainer = new google.ima.AdDisplayContainer(
                adContainer,
                adAudioContent
            )

            // eslint-disable-next-line no-undef
            const adsLoader = new google.ima.AdsLoader(adsDisplayContainer)

            dispatch(
                initializeIma({
                    adAudioPlayer: { adContainer, adAudioContent },
                    adsDisplayContainer,
                    adsLoader,
                    adsManager: null,
                })
            )
        } catch (e) {
            console.error(e)
        }
    }, [imaScriptLoaded, dispatch])

    return (
        <>
            <NextNProgress
                options={{ showSpinner: false }}
                color={
                    router.pathname.includes('[genreName]/catalog')
                        ? 'none'
                        : '#fad810'
                }
                height={4}
                disableSameRoute={true}
            />
            <SessionProvider session={session} refetchOnWindowFocus={false}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=5"
                    />
                    <meta
                        name="facebook-domain-verification"
                        content="nlzbgsri3wlnfjnzq7dezjrzm6lzuo"
                    />
                    <link rel="canonical" href={canonicalUrl} />
                </Head>
                <IMAProvider>
                    <>
                        {isNotLoggedIn && (
                            <Layout>
                                <Script
                                    src="/customScript.js"
                                    // onLoad={() =>
                                    //     console.log('successfully loaded')
                                    // }
                                />
                                <Component {...pageProps} />
                            </Layout>
                        )}
                        {!isNotLoggedIn && (
                            <BaseLayout>
                                <Script
                                    src="/customScript.js"
                                    // onLoad={() =>
                                    //     console.log('successfully loaded')
                                    // }
                                />
                                <Component {...pageProps} />
                            </BaseLayout>
                        )}
                    </>
                </IMAProvider>

                <AuthLayer />

                <Script
                    src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"
                    onLoad={() => {
                        setImaScriptLoaded(true)
                    }}
                />
                <Script id="google-tag-manager" strategy="afterInteractive">
                    {`
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
                  `}
                </Script>
            </SessionProvider>
        </>
    )
}

export default wrapper.withRedux(NoiceApp)
