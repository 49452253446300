import { INITIALIZE_IMA } from '@/store/types/ima'

const initialState = {
    adAudioPlayer: null,
    adsDisplayContainer: null,
    adsLoader: null,
    adsManager: null,
    initialized: false,
}

const imaReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_IMA:
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}

export default imaReducer
