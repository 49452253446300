/**
 * Convert URLSearchParams to object
 * @param {Iterator} URLSearchParams Iterator
 * @return {object} Converted object
 */

export const paramsToObject = (entries) => {
    const result = {}
    for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tupple
        result[key] = value
    }
    return result
}
