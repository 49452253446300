export const TABTYPES = {
    FOR_YOU: 'Buat Kamu',
    PODCAST: 'Podcast',
    ORIGINAL_SERIES: 'Series',
    RADIO: 'Radio',
    AUDIOBOOK: 'Audiobook',
}
export const NAVTYPES = {
    HOME: 'Beranda',
    GENRE: 'Genre',
    SEARCH: 'Cari',
    BUKA_APP: 'Buka App',
    COLLECTION: 'Koleksimu',
    PROFILE: 'Profil',
    SYARAT_DAN_KETENTUAN: 'Syarat dan Ketentuan',
    KEBIJAKAN_PRIVASI: 'Kebijakan Privasi',

    PURCHASED_EPISODES: 'Konten yang Dibeli',

    COLLECTION_HISTORY: 'History',
    COLLECTION_LIKES: 'Konten disukai',
    COLLECTION_PODCAST: 'Podcast',
    COLLECTION_AUDIOSERIES: 'Audioseries',
    COLLECTION_RADIO: 'Radio',
    COLLECTION_AUDIOBOOK: 'Audiobook',
    COLLECTION_KELAS: 'Kelas',
    COLLECTION_PLAYLIST: 'Playlist',
}

// Action Types
export const UPDATE_TAB = 'UPDATE_TAB'
export const UPDATE_NAV = 'UPDATE_NAV'

export const SEGMENT_LIST = 'SEGMENT_LIST'
export const UPDATE_OFFSET = 'UPDATE_OFFSET'

export const UPDATE_SEGMENT_STATUS = 'UPDATE_SEGMENT_STATUS'

export const GET_BANNER = 'GET_BANNER'
export const UPDATE_BANNER_STATUS = 'UPDATE_BANNER_STATUS'

export const GET_SEGMENT_DETAIL = 'GET_SEGMENT_DETAIL'
export const UPDATE_SEGMENT_DETAIL_STATUS = 'UPDATE_SEGMENT_DETAIL_STATUS'

export const RESET_SEGMENT_LIST = 'RESET_SEGMENT_LIST'

export const UPDATE_RADIO_IMAGE_LOADED = 'UPDATE_RADIO_IMAGE_LOADED'

export const UPDATE_ELAPSED_TIME = 'UPDATE_ELAPSED_TIME'
