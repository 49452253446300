import { getURLWithSearchParams } from '@/helpers/getURLWithSearchParams'

/**
 * GET URL with new params
 * @param {string} urlString The URL
 * @param {object} paramsToAdd object to add to the url
 * @return {string} updated url with params
 */
export const addParamsToUrl = (urlString, paramsToAdd = {}) => {
    const { url, searchParams } = getURLWithSearchParams(urlString)

    const updatedSearchParams = new URLSearchParams({
        ...searchParams,
        ...paramsToAdd,
    }).toString()

    const updatedUrl = url.origin + url.pathname + '?' + updatedSearchParams

    return updatedUrl
}
