import { INITIALIZE_COIN_PACKAGES } from '@/store/types/coin'

const initialState = {
    coinPackages: [],
}

const coinReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_COIN_PACKAGES:
            return {
                ...state,
                coinPackages: action.payload,
            }

        default:
            return state
    }
}

export default coinReducer
