import {
    INITIALIZE_CATALOG_PAGE,
    SET_SORTING,
    SET_SEASON,
    RESET_CATALOG_PAGE,
    FETCH_CATALOG_STATUS,
    FETCH_PODCAST_RECOMMENDATIONS,
} from '../types/catalog'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
    catalogData: {},
    sorting: 'Terbaru',
    season: 0,
    fetchCatalogStatus: 'fetching',
    podcastRecommendations: {},
}

const catalogReducer = (state = initialState, action) => {
    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
                ...action.payload.catalogReducer,
            }

        case INITIALIZE_CATALOG_PAGE:
            return {
                ...state,
                catalogData: action.payload,
            }
        case SET_SORTING:
            return {
                ...state,
                sorting: action.payload,
            }

        case SET_SEASON:
            return {
                ...state,
                season: action.payload,
            }
        case RESET_CATALOG_PAGE:
            return {
                ...state,
                catalogData: {},
                sorting: 'Terbaru',
            }
        case FETCH_CATALOG_STATUS:
            return {
                ...state,
                fetchCatalogStatus: action.payload,
            }
        case FETCH_PODCAST_RECOMMENDATIONS:
            return {
                ...state,
                podcastRecommendations: action.payload,
            }

        default:
            return state
    }
}

export default catalogReducer
