import { INITIALIZE_ADS_SETTINGS } from '../types/adsSettings'

const initialState = {}

const adsSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_ADS_SETTINGS:
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}

export default adsSettingsReducer
