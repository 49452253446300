import {
    INITIALIZE_SUBSCRIPTION_CATALOG_LIST,
    FETCH_SUBSCRIPTION_CATALOG_LIST_STATUS,
    RESET_SUBSCRIPTION_CATALOG_LIST,
    INITIALIZE_SUBSCRIPTION,
    FETCH_SUBSCRIPTION_STATUS,
    RESET_SUBSCRIPTION,
} from '../types/subscription'
import uniqBy from 'lodash/uniqBy'

const initialState = {
    subscriptionCatalogs: [],
    subscriptionCatalogsCount: 0,
    subscriptionCatalogsStatus: 'fetching',
    subscriptionDetails: {},
    subscriptionDetailsStatus: 'fetching',
}

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_SUBSCRIPTION_CATALOG_LIST:
            return {
                ...state,
                subscriptionCatalogs: uniqBy(
                    [
                        ...state.subscriptionCatalogs,
                        ...(action?.payload?.data || {}),
                    ],
                    'id'
                ),
                subscriptionCatalogsCount: action.payload?.meta?.totalCount,
            }

        case FETCH_SUBSCRIPTION_CATALOG_LIST_STATUS:
            return {
                ...state,
                subscriptionCatalogsStatus: action.payload,
            }

        case RESET_SUBSCRIPTION_CATALOG_LIST:
            return {
                ...state,
                subscriptionCatalogs: [],
                subscriptionCatalogsCount: 0,
            }

        case INITIALIZE_SUBSCRIPTION:
            return {
                ...state,
                subscriptionDetails: action.payload.find(
                    (p) => p.packageType === 'premium'
                ),
                noiceLiteSubscriptionDetails: action.payload.find(
                    (p) => p.packageType === 'lite'
                ),
            }

        case FETCH_SUBSCRIPTION_STATUS:
            return {
                ...state,
                subscriptionDetailsStatus: action.payload,
            }

        case RESET_SUBSCRIPTION:
            return {
                ...state,
                subscriptionDetails: {},
            }

        default:
            return state
    }
}

export default subscriptionReducer
