import { INITIALIZE_COIN_PURCHASE } from '@/store/types/purchase'

const initialState = {
    coin: null,
}

const purchaseReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_COIN_PURCHASE:
            return {
                ...state,
                coin: action.payload,
            }

        default:
            return state
    }
}

export default purchaseReducer
