import adsReducer from './ads'
import adsSettingsReducer from './adsSettings'
import campaignReducer from './campaign'
import collectionReducer from './collection'
import commonReducer from './common'
import imaReducer from './ima'
import themepageReducer from './themepage'
import catalogReducer from '@/store/reducers/catalog'
import coinReducer from '@/store/reducers/coin'
import commentsReducer from '@/store/reducers/comment'
import companionAdsReducer from '@/store/reducers/companionAds'
import contentReducer from '@/store/reducers/content'
import genresReducer from '@/store/reducers/genre'
import homeReducer from '@/store/reducers/home'
import livestreamReducer from '@/store/reducers/livestream'
import playerReducer from '@/store/reducers/player'
import pubnubReducer from '@/store/reducers/pubnub'
import purchaseReducer from '@/store/reducers/purchase'
import searchReducer from '@/store/reducers/search'
import subscriptionReducer from '@/store/reducers/subscription'
import tagsReducer from '@/store/reducers/tag'
import userReducer from '@/store/reducers/user'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
    homeReducer,
    catalogReducer,
    playerReducer,
    contentReducer,
    commentsReducer,
    livestreamReducer,
    searchReducer,
    genresReducer,
    userReducer,
    commonReducer,
    tagsReducer,
    collectionReducer,
    adsSettingsReducer,
    adsReducer,
    campaignReducer,
    themepageReducer,
    imaReducer,
    companionAdsReducer,
    coinReducer,
    purchaseReducer,
    pubnubReducer,
    subscriptionReducer,
})

export default rootReducer
