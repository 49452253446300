import {
    GET_SEARCH_RESULT,
    INITIALIZE_SEARCH_PAGE,
    UPDATE_FETCH_CONTENT_STATUS,
    UPDATE_FETCH_SEARCH_STATUS,
    UPDATE_SEARCH_TERM,
    GET_MORE_SEARCH_RESULT,
    UPDATE_FILTER,
    UPDATE_FOCUS,
} from '@/store/types/search'

const initialState = {
    focus: false,
    contents: [],
    results: [],
    term: '',
    page: 0,
    filters: '',
    fetchContentStatus: 'fetching',
    fetchSearchStatus: 'fetching',
    totalSearchCount: 0,
}

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_SEARCH_PAGE:
            return {
                ...state,
                contents: action?.payload?.data,
            }
        case UPDATE_FETCH_CONTENT_STATUS:
            return {
                ...state,
                fetchContentStatus: action.payload,
            }
        case UPDATE_SEARCH_TERM:
            return {
                ...state,
                term: action.payload,
            }
        case GET_SEARCH_RESULT:
            return {
                ...state,
                results: action?.payload?.data,
                totalSearchCount: action.payload?.meta?.total || 0,
            }
        case UPDATE_FETCH_SEARCH_STATUS:
            return {
                ...state,
                fetchSearchStatus: action.payload,
            }
        case GET_MORE_SEARCH_RESULT:
            return {
                ...state,
                results: [...state.results, ...action?.payload?.data],
            }
        case UPDATE_FILTER:
            return {
                ...state,
                filters: state.filters === action.payload ? '' : action.payload,
            }
        case UPDATE_FOCUS:
            return {
                ...state,
                focus: action.payload,
            }
        default:
            return state
    }
}

export default searchReducer
