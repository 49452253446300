import {
    INITIALIZE_CAMPAIGN_DETAIL_PAGE,
    RESET_CAMPAIGN_DETAIL_PAGE,
    INITIALIZE_REWARD_DETAIL_PAGE,
    INITIALIZE_GIFTS_DETAIL_PAGE,
    INITIALIZE_TERMS_CONDITIONS_PAGE,
    INITIALIZE_LEADERBOARD_PAGE,
    INITIALIZE_CAMPAIGN_PARTICIPANT_STATUS,
    INITIALIZE_CAMPAIGN_LIST_PAGE,
    FETCH_CAMPAIGN_LIST_STATUS,
    UPDATE_CAMPAIGN_STATUS,
} from '../types/campaign'
import uniqBy from 'lodash/uniqBy'

const initialState = {
    campaignList: [],
    campaignListCount: 0,
    campaignDetails: {},
    rewardDetails: {},
    giftsDetails: [],
    termsAndConditions: {},
    leaderboardDetails: {},
    leaderboardCurrentUser: {},
    leaderboardUsers: [],
    leaderboardUsersCount: 0,
    campaignParticipantStatus: '',
    campaignListStatus: 'fetching',
}

const campaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_CAMPAIGN_DETAIL_PAGE:
            return {
                ...state,
                campaignDetails: action.payload,
            }

        case INITIALIZE_REWARD_DETAIL_PAGE:
            return {
                ...state,
                rewardDetails: action.payload,
            }

        case INITIALIZE_GIFTS_DETAIL_PAGE:
            return {
                ...state,
                giftsDetails: action.payload,
            }

        case INITIALIZE_TERMS_CONDITIONS_PAGE:
            return {
                ...state,
                termsAndConditions: action.payload,
            }

        case INITIALIZE_LEADERBOARD_PAGE:
            return {
                ...state,
                leaderboardDetails: action.payload,
                leaderboardCurrentUser: action.payload?.currentUser,
                leaderboardUsers: action.payload?.totalUsers
                    ? uniqBy(
                          [
                              ...state.leaderboardUsers,
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              ...action?.payload?.leaderboard,
                          ],
                          'id'
                      )
                    : [],
                leaderboardUsersCount: action.payload?.totalUsers,
            }

        case INITIALIZE_CAMPAIGN_PARTICIPANT_STATUS:
            return {
                ...state,
                campaignParticipantStatus: action.payload,
            }

        case INITIALIZE_CAMPAIGN_LIST_PAGE:
            return {
                ...state,

                campaignList: uniqBy(
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    [...state.campaignList, ...action?.payload?.data],
                    'id'
                ),
                campaignListCount: action.payload?.meta?.totalCount,
            }

        case FETCH_CAMPAIGN_LIST_STATUS:
            return {
                ...state,
                campaignListStatus: action.payload,
            }

        case UPDATE_CAMPAIGN_STATUS:
            return {
                ...state,
                campaignDetails: {
                    ...state.campaignDetails,
                    status: action.payload,
                },
            }

        case RESET_CAMPAIGN_DETAIL_PAGE:
            return {
                ...state,
                campaignDetails: {},
                rewardDetails: {},
                giftsDetails: [],
                termsAndConditions: {},
                leaderboardDetails: {},
                leaderboardCurrentUser: {},
                leaderboardUsers: [],
                leaderboardUsersCount: 0,
                campaignParticipantStatus: '',
            }

        default:
            return state
    }
}

export default campaignReducer
