import {
    GET_COLLECTION_DETAIL,
    RECENT_PLAYED_LIST,
    RESET_COLLECTION_DETAIL,
    RESET_RECENT_PLAYED_LIST,
    UPDATE_COLLECTION_ELAPSED_TIME,
    UPDATE_RECENT_PLAYED_STATUS,
} from '../types/collection'
import cloneDeep from 'lodash/cloneDeep'
import uniqBy from 'lodash/uniqBy'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
    recentPlayedList: [],
    fetchRecentPlayedStatus: 'fetching',
    collectionData: [],
    totalCollectionCount: 0,
}

const collectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
                ...action.payload,
            }
        case '__NEXT_REDUX_WRAPPER_HYDRATE__':
            return {
                ...state,
                ...action.payload,
            }

        case RECENT_PLAYED_LIST:
            return {
                ...state,
                recentPlayedList: uniqBy(
                    [...state.recentPlayedList, ...action?.payload?.data],
                    'id'
                ),
                totalRecentPlayedCount: parseInt(action.payload.meta.total),
            }

        case UPDATE_RECENT_PLAYED_STATUS:
            return {
                ...state,
                fetchRecentPlayedStatus: action.payload,
            }

        case RESET_RECENT_PLAYED_LIST:
            return {
                ...state,
                recentPlayedList: [],
            }

        case GET_COLLECTION_DETAIL:
            return {
                ...state,
                collectionData: uniqBy(
                    [...state.collectionData, ...action?.payload?.data],
                    'id'
                ),
                totalCollectionCount: parseInt(action.payload.meta.total),
            }

        case RESET_COLLECTION_DETAIL:
            return {
                ...state,
                collectionData: [],
                totalCollectionCount: 0,
            }

        case UPDATE_COLLECTION_ELAPSED_TIME: {
            const list = cloneDeep(state.recentPlayedList)
            const findRecentPlayed = list.find(
                (singleRecentPlayed) =>
                    singleRecentPlayed.id === action.payload.id
            )

            if (findRecentPlayed) {
                findRecentPlayed.meta.timeElapsed = action.payload.timeElapsed
            }

            return {
                ...state,
                recentPlayedList: list,
            }
        }

        default:
            return state
    }
}

export default collectionReducer
