import {
    INITIALIZE_LIVESTREAM_PAGE,
    SET_LIVEROOM_PARTICIPANT_COUNT,
    SET_LIVE_SPEAKERS,
    UPDATE_LIVESTREAM_STATUS,
    COUNTDOWN_STATUS,
} from '../types/livestream'

const initialState = {
    livestreamData: {},
    liveroomParticipantCount: 0,
    liveSpeakers: [],
    countdownStatus: 'ticking',
}

const livestreamReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_LIVESTREAM_PAGE:
            return {
                ...state,
                livestreamData: action.payload,
                countdownStatus: 'ticking',
            }

        case SET_LIVEROOM_PARTICIPANT_COUNT:
            return {
                ...state,
                liveroomParticipantCount: action.payload,
            }
        case SET_LIVE_SPEAKERS:
            return {
                ...state,
                liveSpeakers: action.payload,
            }

        case UPDATE_LIVESTREAM_STATUS:
            return {
                ...state,
                livestreamData: {
                    ...state.livestreamData,
                    status: action.payload,
                },
            }
        case COUNTDOWN_STATUS:
            return {
                ...state,
                countdownStatus: action.payload,
            }

        default:
            return state
    }
}

export default livestreamReducer
