export const INITIALIZE_GENRE = 'INITIALIZE_GENRE'
export const UPDATE_GENRE_STATUS = 'UPDATE_GENRE_STATUS'
export const GENRE_DETAIL = 'GENRE_DETAIL'
export const GENRE_DETAIL_STATUS = 'GENRE_DETAIL_STATUS'
export const RESET_GENRE = 'RESET_GENRE'
//programmatic landing page types
export const PROGRAMMATIC_LANDING_PAGE_GENRE = 'PROGRAMMATIC_LANDING_PAGE_GENRE'
export const RESET_PROGRAMMATIC_LANDING_PAGE_GENRE =
    'RESET_PROGRAMMATIC_LANDING_PAGE_GENRE'

// Genre segment list
export const GENRE_SEGMENT_LIST = 'GENRE_SEGMENT_LIST'
export const RESET_GENRE_SEGMENT_LIST = 'RESET_GENRE_SEGMENT_LIST'
