import { INITIALIZE_COMPANION_ADS } from '../types/companionAds'

const initialState = {
    miniPlayer: {
        thumb: null,
        link: null,
    },
    player: {
        thumb: null,
        link: null,
    },
}

const companionAdsReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_COMPANION_ADS:
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}

export default companionAdsReducer
