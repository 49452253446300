export const RECENT_PLAYED_LIST = 'RECENT_PLAYED_LIST'

export const UPDATE_RECENT_PLAYED_STATUS = 'UPDATE_RECENT_PLAYED_STATUS'

export const GET_COLLECTION_DETAIL = 'GET_COLLECTION_DETAIL'

export const RESET_RECENT_PLAYED_LIST = 'RESET_RECENT_PLAYED_LIST'

export const RESET_COLLECTION_DETAIL = 'RESET_COLLECTION_DETAIL'

export const UPDATE_COLLECTION_ELAPSED_TIME = 'UPDATE_COLLECTION_ELAPSED_TIME'
