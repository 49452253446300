import { SET_PUBNUB_INSTANCE } from '../types/pubnub'

const initialState = null

const pubnubReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PUBNUB_INSTANCE:
            return action.payload

        default:
            return state
    }
}

export default pubnubReducer
