import { INITIALIZE_COMMENT_FOR_CONTENT_PAGE } from '../types/comment'

const initialState = {
    comments: null,
}

const commentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_COMMENT_FOR_CONTENT_PAGE:
            return {
                ...state,
                comments: action.payload,
            }

        default:
            return state
    }
}

export default commentsReducer
