import {
    SET_PLAYING,
    SET_AUTO_PLAYED,
    SET_SOUND_VALUE,
    SET_CURRENT_TRACK_ID,
    SET_PLAYLIST,
    SET_SHOW_PLAYER,
    SET_SHOW_DOWNLOAD_PROMPT,
    SET_SHOW_SUBSCRIPTION_PROMPT,
    SET_TOGGLE_SCREEN,
    SET_LISTENER_COUNT,
    SET_TRACK_COUNT,
    SET_PLAYLIST_DETAILS,
    RESET_PLAYLIST_DETAILS,
    SET_CURRENT_PLAYING_CATALOG,
    SET_JOINED_LIVEROOM,
    SET_CURRENT_LIVEROOM_PARTICIPANT_COUNT,
    SET_CURRENT_LIVE_SPEAKERS,
    SET_CURRENT_LIVEROOM,
    SET_PLAYING_RADIO_LISTENER_COUNT,
    SET_SHOW_LOGIN_PROMPT,
    SET_CURRENT_CONTENT_ID,
    SET_ELAPSED_TIME,
} from '../types/player'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
    playing: false,
    autoPlayed: false,
    soundValue: 0,
    currentTrackId: '',
    playlist: [],
    showPlayer: false,
    showDownloadPrompt: false,
    showSubscriptionPrompt: false,
    promptText: '',
    toggleScreen: false,
    listenerCount: 0,
    playingRadioListenerCount: 0,
    trackCount: 0,
    playlistDetails: {},
    currentPlayingCatalog: {},
    joinedLiveroom: false,
    currentLiveroomParticipantCount: 0,
    currentLiveSpeakers: [],
    currentLiveroom: {},
    showLoginPrompt: false,
    currentContentId: '',
    contentElapsedTimeType: '',
}

const playerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLAYING:
            return {
                ...state,
                playing: action.payload,
            }

        case SET_AUTO_PLAYED:
            return {
                ...state,
                autoPlayed: action.payload,
            }

        case SET_SOUND_VALUE:
            return {
                ...state,
                soundValue: action.payload,
            }

        case SET_PLAYLIST:
            return {
                ...state,
                playlist: action.payload,
            }
        case SET_CURRENT_TRACK_ID:
            return {
                ...state,
                currentTrackId: action.payload,
            }
        case SET_SHOW_PLAYER:
            return {
                ...state,
                showPlayer: action.payload,
            }

        case SET_SHOW_DOWNLOAD_PROMPT:
            return {
                ...state,
                showDownloadPrompt: action.payload?.state
                    ? action.payload.state
                    : action.payload,
                promptText: action.payload?.text
                    ? action.payload?.text
                    : 'Buka App untuk Akses Fitur Ini',
            }
        case SET_SHOW_SUBSCRIPTION_PROMPT:
            return {
                ...state,
                showSubscriptionPrompt: action.payload?.state,
                selectedEpisodePrice: action.payload?.selectedEpisodePrice,
                onlyEpisode: action.payload?.onlyEpisode,
                currentSubscriptionContentId:
                    action.payload?.currentSubscriptionContentId,
                currentSubscriptionContentDetails:
                    action.payload?.currentSubscriptionContentDetails,
            }
        case SET_TOGGLE_SCREEN:
            return {
                ...state,
                toggleScreen: action.payload,
            }
        case SET_LISTENER_COUNT:
            return {
                ...state,
                listenerCount: action.payload,
            }
        case SET_TRACK_COUNT:
            return {
                ...state,
                trackCount: action.payload,
            }

        case SET_PLAYLIST_DETAILS:
            return {
                ...state,
                playlistDetails: action.payload,
            }

        case RESET_PLAYLIST_DETAILS:
            return {
                ...state,
                playlistDetails: {},
            }
        case SET_CURRENT_PLAYING_CATALOG:
            return {
                ...state,
                currentPlayingCatalog: action.payload,
            }
        case SET_JOINED_LIVEROOM:
            return {
                ...state,
                joinedLiveroom: action.payload,
            }
        case SET_CURRENT_LIVEROOM_PARTICIPANT_COUNT:
            return {
                ...state,
                currentLiveroomParticipantCount: action.payload,
            }
        case SET_CURRENT_LIVE_SPEAKERS:
            return {
                ...state,
                currentLiveSpeakers: action.payload,
            }
        case SET_CURRENT_LIVEROOM:
            return {
                ...state,
                currentLiveroom: action.payload,
            }
        case SET_PLAYING_RADIO_LISTENER_COUNT:
            return {
                ...state,
                playingRadioListenerCount: action.payload,
            }
        case SET_SHOW_LOGIN_PROMPT:
            return {
                ...state,
                showLoginPrompt: action.payload,
            }
        case SET_CURRENT_CONTENT_ID:
            return {
                ...state,
                currentContentId: action.payload,
            }

        case SET_ELAPSED_TIME: {
            const list = cloneDeep(state.playlist)
            const findContent = list.find(
                (singleContent) => singleContent?.id === action.payload?.id
            )

            if (findContent) {
                findContent.meta.timeElapsed = action.payload.timeElapsed
            }

            return {
                ...state,
                playlist: list,
                contentElapsedTimeType: action.payload.segmentName
                    ? action.payload.segmentName
                    : '',
            }
        }

        default:
            return state
    }
}

export default playerReducer
