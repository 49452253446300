import { INITIALIZE_ADS } from '../types/ads'

const initialState = {
    adError: false,
    adPlaying: false,
    showingAd: false,
    playAd: false,
    adPlayingToast: false,
    toastMessage: 'Ada iklan bentar ya, abis itu kontennya mulai',
}

const adsReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_ADS:
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}

export default adsReducer
