import {
    UPDATE_HEADER_VISIBILITY,
    SET_SEGMENT_NAME,
    RESET_SEGMENT_NAME,
} from '@/store/types/common'

const initialState = {
    showHeader: true,
    segmentName: '',
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_HEADER_VISIBILITY:
            return {
                ...state,
                showHeader: action.payload,
            }

        case SET_SEGMENT_NAME:
            return {
                ...state,
                segmentName: action.payload,
            }

        case RESET_SEGMENT_NAME:
            return {
                ...state,
                segmentName: '',
            }
        default:
            return { ...state }
    }
}

export default commonReducer
