import { paramsToObject } from './paramsToObject'

/**
 * GET URL with Search params as an object
 * @param {string} url string
 * @return {object} Converted object containing url and searchParams
 */
export const getURLWithSearchParams = (url) => {
    const urlObj = new URL(url)
    const search = urlObj?.search

    let searchParamsIterator = new URLSearchParams(search)

    const searchParams = paramsToObject(searchParamsIterator.entries())

    return {
        url: urlObj,
        searchParams,
    }
}
