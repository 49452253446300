import { useSession } from 'next-auth/react'
import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'

export const useAuthLayer = () => {
    const posthog = usePostHog()

    const { data: session } = useSession()

    const userInfo = session?.userInfo
    const userId = userInfo?.id

    const isGuest = session?.isGuest
    const guestUserId = session?.guestUserId

    useEffect(() => {
        if (posthog && userId) {
            posthog.identify(userId, { ...userInfo })
        } else if (isGuest && guestUserId) {
            posthog.identify(guestUserId, { isGuest: true, guestUserId })
        }
    }, [posthog, userId, userInfo, isGuest, guestUserId])
}
