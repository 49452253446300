import {
    INITIALIZE_USER_PROFILE_PAGE,
    RESET_USER_PROFILE_PAGE,
    FETCH_USER_PROFILE_STATUS,
    PROFILETABTYPES,
    UPDATE_PROFILE_TAB,
    SET_PROFILE_DETAILS,
    SET_FOLLOWERS,
    SET_FOLLOWING,
    RESET_FOLLOWERS_PAGE,
    FETCH_FOLLOWERS_STATUS,
    RESET_FOLLOWING_PAGE,
    FETCH_FOLLOWING_STATUS,
    SET_SHOW_REGISTRATION_PROMPT,
    SET_USER_WALLET,
    SET_USER_WALLET_TRANSACTION,
    SET_USER_SUBSCRIPTION,
} from '../types/user'
import uniqBy from 'lodash/uniqBy'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
    userDetails: {},
    userCatalogs: [],
    totalUserCatalogCount: 0,
    userActivities: [],
    totalUserActivitiesCount: 0,
    fetchUserProfileStatus: 'fetching',
    activeProfileTab: PROFILETABTYPES.KARYA,
    profileDetails: {},
    followers: [],
    totalFollowersCount: 0,
    fetchFollowersStatus: 'fetching',
    following: [],
    totalFollowingCount: 0,
    fetchFollowingStatus: 'fetching',
    showRegistrationPrompt: false,
    wallet: {},
    walletTransactions: [],
    userSubscription: [],
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
                userDetails: action.payload?.userReducer?.userDetails,
                userCatalogs: action.payload?.userReducer?.userCatalogs,
                totalUserCatalogCount:
                    action.payload?.userReducer?.totalUserCatalogCount,
                userActivities: action.payload?.userReducer?.userActivities,
                totalUserActivitiesCount:
                    action.payload?.userReducer?.totalUserActivitiesCount,
            }

        case UPDATE_PROFILE_TAB:
            return {
                ...state,
                activeProfileTab: action.payload,
            }
        case INITIALIZE_USER_PROFILE_PAGE:
            return {
                ...state,
                userDetails: action.payload.user.data,
                userCatalogs: uniqBy(
                    [...state.userCatalogs, ...action.payload.catalog.data],
                    'id'
                ),
                totalUserCatalogCount: action.payload.catalog.meta.totalCount,
                userActivities: uniqBy(
                    [...state.userActivities, ...action.payload.community.data],
                    'item.id'
                ),
                totalUserActivitiesCount:
                    action.payload.community.meta.totalCount,
            }

        case RESET_USER_PROFILE_PAGE:
            return {
                ...state,
                userDetails: {},
                userCatalogs: [],
                userActivities: [],
                totalUserCatalogCount: 0,
                totalUserActivitiesCount: 0,
            }

        case FETCH_USER_PROFILE_STATUS:
            return {
                ...state,
                fetchUserProfileStatus: action.payload,
            }
        case SET_PROFILE_DETAILS:
            return {
                ...state,
                profileDetails: action.payload.user.data,
            }

        case SET_FOLLOWERS:
            return {
                ...state,
                followers: uniqBy(
                    [...state.followers, ...action?.payload?.data],
                    'id'
                ),
                totalFollowersCount: action.payload.meta.totalCount,
            }

        case SET_FOLLOWING:
            return {
                ...state,
                following: uniqBy(
                    [...state.following, ...action?.payload?.data],
                    'id'
                ),
                totalFollowingCount: action.payload.meta.totalCount,
            }

        case RESET_FOLLOWERS_PAGE:
            return {
                ...state,
                followers: [],
                totalFollowersCount: 0,
            }

        case FETCH_FOLLOWERS_STATUS:
            return {
                ...state,
                fetchFollowersStatus: action.payload,
            }

        case RESET_FOLLOWING_PAGE:
            return {
                ...state,
                following: [],
                totalFollowingCount: 0,
            }

        case FETCH_FOLLOWING_STATUS:
            return {
                ...state,
                fetchFollowingStatus: action.payload,
            }
        case SET_SHOW_REGISTRATION_PROMPT:
            return {
                ...state,
                showRegistrationPrompt: action.payload,
            }
        case SET_USER_WALLET:
            return {
                ...state,
                wallet: action.payload,
            }

        case SET_USER_WALLET_TRANSACTION:
            return {
                ...state,
                walletTransactions: uniqBy(
                    [...state.walletTransactions, ...action?.payload],
                    'id'
                ),
            }

        case SET_USER_SUBSCRIPTION:
            return {
                ...state,
                userSubscription: uniqBy(
                    [...state.userSubscription, ...action.payload],
                    'id'
                ),
            }

        default:
            return state
    }
}

export default userReducer
