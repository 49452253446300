import { getFetcher } from './auth'

export const getAdSettings = async (token) => {
    try {
        if (!token) return

        const data = await getFetcher(
            'catalog-api/v2/ads-config?page=6&limit=0&isPremium=true',
            token,
            {
                platform: 'web',
            }
        )
        return data
    } catch (err) {
        console.log('error',err);
        return false
    }
}
