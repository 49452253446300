export const INITIALIZE_CAMPAIGN_DETAIL_PAGE = 'INITIALIZE_CAMPAIGN_DETAIL_PAGE'
export const RESET_CAMPAIGN_DETAIL_PAGE = 'RESET_CAMPAIGN_DETAIL_PAGE'

export const INITIALIZE_REWARD_DETAIL_PAGE = 'INITIALIZE_REWARD_DETAIL_PAGE'
export const INITIALIZE_GIFTS_DETAIL_PAGE = 'INITIALIZE_GIFTS_DETAIL_PAGE'
export const INITIALIZE_TERMS_CONDITIONS_PAGE =
    'INITIALIZE_TERMS_CONDITIONS_PAGE'
export const INITIALIZE_LEADERBOARD_PAGE = 'INITIALIZE_LEADERBOARD_PAGE'
export const INITIALIZE_CAMPAIGN_PARTICIPANT_STATUS =
    'INITIALIZE_CAMPAIGN_PARTICIPANT_STATUS'
export const INITIALIZE_CAMPAIGN_LIST_PAGE = 'INITIALIZE_CAMPAIGN_LIST_PAGE'
export const FETCH_CAMPAIGN_LIST_STATUS = 'FETCH_CAMPAIGN_LIST_STATUS'
export const UPDATE_CAMPAIGN_STATUS = 'UPDATE_CAMPAIGN_STATUS'
