import BootstrapToast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'

export const Toast = ({
    message,
    showToast,
    setShowToast,
    backgroundColor = '#1F1F1F',
    color = '#FFFFFF',
}) => {
    return (
        <ToastContainer
            className="p-3 position-fixed"
            position="top-center"
            style={{ zIndex: 1000 }}
        >
            <BootstrapToast
                autohide
                delay={3000}
                show={showToast}
                onClose={() => setShowToast(false)}
                style={{ backgroundColor }}
            >
                <BootstrapToast.Body
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        fontSize: '16px',
                        color,
                        padding: '14px',
                    }}
                >
                    {message}
                </BootstrapToast.Body>
            </BootstrapToast>
        </ToastContainer>
    )
}
