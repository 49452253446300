import {
    TABTYPES,
    NAVTYPES,
    UPDATE_TAB,
    UPDATE_NAV,
    SEGMENT_LIST,
    UPDATE_OFFSET,
    UPDATE_SEGMENT_STATUS,
    GET_BANNER,
    UPDATE_BANNER_STATUS,
    GET_SEGMENT_DETAIL,
    UPDATE_SEGMENT_DETAIL_STATUS,
    RESET_SEGMENT_LIST,
    UPDATE_RADIO_IMAGE_LOADED,
    UPDATE_ELAPSED_TIME,
} from '@/store/types/home'
import cloneDeep from 'lodash/cloneDeep'
import uniqBy from 'lodash/uniqBy'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
    activeTab: TABTYPES.FOR_YOU,
    activeNav: NAVTYPES.HOME,
    segmentList: [],
    homeSegmentList: [],
    podcastSegmentList: [],
    radioSegmentList: [],
    audiobookSegmentList: [],
    audioseriesSegmentList: [],
    livestreamSegmentList: [],
    homeOffset: 1,
    podcastOffset: 1,
    radioOffset: 1,
    audiobookOffset: 1,
    audioseriesOffset: 1,
    livestreamOffset: 1,
    fetchSegmentStatus: 'fetching',
    totalSegmentCount: 0,
    totalHomeSegmentCount: 0,
    totalPodcastSegmentCount: 0,
    totalRadioSegmentCount: 0,
    totalAudiobookSegmentCount: 0,
    totalAudioseriesSegmentCount: 0,
    totalLivestreamSegmentCount: 0,
    banner: {
        home: [],
        podcast: [],
        audiobook: [],
        audioseries: [],
    },
    fetchBannerStatus: 'fetching',
    segmentDetail: [],
    segmentContent: [],
    fetchSegmentDetailStatus: 'fetching',
    totalSegmentDetailCount: 0,
    radioImageLoaded: 0,
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
                ...action.payload.homeReducer,
            }

        // case HYDRATE: {
        //     if (action.payload?.homeReducer?.segmentDetail?.length > 0) {
        //         const segmentDetailDataFromServer =
        //             action.payload?.homeReducer?.segmentDetail
        //         const segmentContentDataFromServer =
        //             action.payload?.homeReducer?.segmentContent

        //         return {
        //             ...state,
        //             segmentDetail: uniqBy(
        //                 [
        //                     ...state.segmentDetail,
        //                     ...segmentDetailDataFromServer,
        //                 ],
        //                 'id'
        //             ),
        //             segmentContent: uniqBy(
        //                 [
        //                     ...state.segmentContent,
        //                     ...segmentContentDataFromServer,
        //                 ],
        //                 'id'
        //             ),
        //         }
        //     } else if (
        //         action.payload?.homeReducer?.radioSegmentList?.length > 0
        //     ) {
        //         const radioDataFromServer =
        //             action.payload?.homeReducer?.radioSegmentList

        //         return {
        //             ...state,
        //             radioSegmentList: radioDataFromServer,
        //         }
        //     } else {
        //         return {
        //             ...state,
        //             ...action.payload,
        //         }
        //     }
        // }

        // case '__NEXT_REDUX_WRAPPER_HYDRATE__': {
        //     if (action.payload?.homeReducer?.segmentDetail?.length > 0) {
        //         const segmentDetailDataFromServer =
        //             action.payload?.homeReducer?.segmentDetail
        //         const segmentContentDataFromServer =
        //             action.payload?.homeReducer?.segmentContent

        //         return {
        //             ...state,
        //             segmentDetail: uniqBy(
        //                 [
        //                     ...state.segmentDetail,
        //                     ...segmentDetailDataFromServer,
        //                 ],
        //                 'id'
        //             ),
        //             segmentContent: uniqBy(
        //                 [
        //                     ...state.segmentContent,
        //                     ...segmentContentDataFromServer,
        //                 ],
        //                 'id'
        //             ),
        //         }
        //     } else if (
        //         action.payload?.homeReducer?.radioSegmentList?.length > 0
        //     ) {
        //         const radioDataFromServer =
        //             action.payload?.homeReducer?.radioSegmentList

        //         return {
        //             ...state,
        //             radioSegmentList: radioDataFromServer,
        //         }
        //     } else {
        //         return {
        //             ...state,
        //             ...action.payload,
        //         }
        //     }
        // }

        case UPDATE_TAB:
            return {
                ...state,
                activeTab: action.payload,
                segmentList: [],
            }
        case UPDATE_NAV:
            return {
                ...state,
                activeNav: action.payload,
            }
        case SEGMENT_LIST: {
            switch (action.payload.page) {
                case 'home':
                    return {
                        ...state,
                        homeSegmentList: uniqBy(
                            [...state.homeSegmentList, ...action?.payload?.data],
                            'id'
                        ),
                        totalHomeSegmentCount: action.payload.meta.totalCount,
                    }
                case 'podcast':
                    return {
                        ...state,
                        podcastSegmentList: uniqBy(
                            [
                                ...state.podcastSegmentList,
                                ...action?.payload?.data,
                            ],
                            'id'
                        ),
                        totalPodcastSegmentCount:
                            action.payload.meta.totalCount,
                    }
                case 'radio':
                    return {
                        ...state,
                        radioSegmentList: uniqBy(
                            [...state.radioSegmentList, ...action?.payload?.data],
                            'id'
                        ),
                        totalRadioSegmentCount: action.payload.meta.totalCount,
                    }
                case 'audiobook':
                    return {
                        ...state,
                        audiobookSegmentList: uniqBy(
                            [
                                ...state.audiobookSegmentList,
                                ...action?.payload?.data,
                            ],
                            'id'
                        ),
                        totalAudiobookSegmentCount:
                            action.payload.meta.totalCount,
                    }
                case 'audioseries':
                    return {
                        ...state,
                        audioseriesSegmentList: uniqBy(
                            [
                                ...state.audioseriesSegmentList,
                                ...action?.payload?.data,
                            ],
                            'id'
                        ),
                        totalAudioseriesSegmentCount:
                            action.payload.meta.totalCount,
                    }
                case 'livestream':
                    return {
                        ...state,
                        livestreamSegmentList: uniqBy(
                            [
                                ...state.livestreamSegmentList,
                                ...action?.payload?.data,
                            ],
                            'id'
                        ),
                        totalLivestreamSegmentCount:
                            action.payload.meta.totalCount,
                    }
                default:
                    return {
                        ...state,
                        segmentList: uniqBy(
                            [...state.segmentList, ...action?.payload?.data],
                            'id'
                        ),
                        totalSegmentCount: action.payload.meta.totalCount,
                    }
            }
        }
        case UPDATE_OFFSET: {
            switch (action.payload) {
                case 'home':
                    return {
                        ...state,
                        homeOffset: state.homeOffset + 1,
                    }
                case 'podcast':
                    return {
                        ...state,
                        podcastOffset: state.podcastOffset + 1,
                    }
                case 'radio':
                    return {
                        ...state,
                        radioOffset: state.radioOffset + 1,
                    }
                case 'audiobook':
                    return {
                        ...state,
                        audiobookOffset: state.audiobookOffset + 1,
                    }
                case 'audioseries':
                    return {
                        ...state,
                        audioseriesOffset: state.audioseriesOffset + 1,
                    }
                case 'livestream':
                    return {
                        ...state,
                        livestreamOffset: state.livestreamOffset + 1,
                    }
                default:
                    return state
            }
        }
        case UPDATE_SEGMENT_STATUS:
            return {
                ...state,
                fetchSegmentStatus: action.payload,
            }
        case GET_BANNER:
            return {
                ...state,
                banner: {
                    ...state.banner,
                    [action.payload.page]: action?.payload?.data,
                },
            }
        case UPDATE_BANNER_STATUS:
            return {
                ...state,
                fetchBannerStatus: action.payload,
            }
        case GET_SEGMENT_DETAIL:
            return {
                ...state,
                segmentDetail: uniqBy(
                    [...state.segmentDetail, ...action?.payload?.data],
                    'id'
                ),
                segmentContent: uniqBy(
                    [
                        ...state.segmentContent,
                        ...action?.payload?.data[0]?.content,
                    ],
                    'id'
                ),
                totalSegmentDetailCount:
                    action.payload.meta.totalCount ||
                    action?.payload?.data[0]?.viewOptions?.count,
            }
        case UPDATE_SEGMENT_DETAIL_STATUS:
            return {
                ...state,
                fetchSegmentDetailStatus: action.payload,
            }

        case RESET_SEGMENT_LIST:
            return {
                ...state,
                segmentDetail: [],
                segmentContent: [],
                totalSegmentDetailCount: 0,
            }
        case UPDATE_RADIO_IMAGE_LOADED: {
            if (action.payload === 0) {
                return {
                    ...state,
                    radioImageLoaded: 0,
                }
            }
            return {
                ...state,
                radioImageLoaded: state.radioImageLoaded + 1,
            }
        }

        case UPDATE_ELAPSED_TIME: {
            const list = cloneDeep(state.segmentContent)
            const findSegment = list.find(
                (singleSegment) => singleSegment.id === action.payload.id
            )

            if (findSegment) {
                findSegment.meta.timeElapsed = action.payload.timeElapsed
            }

            const homeSegmentList = cloneDeep(state.homeSegmentList)
            const findRecentSegment = homeSegmentList.find(
                (recentSegment) =>
                    recentSegment.dynamicSegmentType === 'RECENT_PLAY'
            )
            const findRecentContent = findRecentSegment?.content.find(
                (recentSegmentContent) =>
                    recentSegmentContent.id === action.payload.id
            )
            if (findRecentContent) {
                findRecentContent.meta.timeElapsed = action.payload.timeElapsed
            }
            return {
                ...state,
                segmentContent: list,
                homeSegmentList: homeSegmentList,
            }
        }

        default:
            return state
    }
}

export default homeReducer
