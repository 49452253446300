import {
    GENRE_DETAIL,
    GENRE_DETAIL_STATUS,
    INITIALIZE_GENRE,
    RESET_GENRE,
    UPDATE_GENRE_STATUS,
    PROGRAMMATIC_LANDING_PAGE_GENRE,
    RESET_PROGRAMMATIC_LANDING_PAGE_GENRE,
    GENRE_SEGMENT_LIST,
    RESET_GENRE_SEGMENT_LIST,
} from '../types/genre'
import uniqBy from 'lodash/uniqBy'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
    genres: [],
    fetchGenresStatus: 'fetching',
    totalCount: 0,
    genreDetail: null,
    genreDetailStatus: 'fetching',
    programmaticLandingPageCatalog: {},
    programmaticLandingPageSegments: [],
    genreSegmentList: [],
    genreSegmentTotalCount: 0,
}

const genresReducer = (state = initialState, action) => {
    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
                ...action.payload.genresReducer,
            }

        case INITIALIZE_GENRE:
            return {
                ...state,
                genres: uniqBy([...state.genres, ...action?.payload?.data], 'id'),
                totalCount: parseInt(action.payload.meta.totalCount),
            }

        case UPDATE_GENRE_STATUS:
            return {
                ...state,
                fetchGenresStatus: action.payload,
            }

        case GENRE_DETAIL:
            return {
                ...state,
                genreDetail: action.payload,
            }

        case GENRE_DETAIL_STATUS:
            return {
                ...state,
                genreDetailStatus: action.payload,
            }
        case RESET_GENRE:
            return {
                ...state,
                genres: [],
                totalCount: 0,
                genreDetail: {},
                fetchGenresStatus: 'fetching',
                genreDetailStatus: 'fetching',
            }
        case PROGRAMMATIC_LANDING_PAGE_GENRE:
            return {
                ...state,
                programmaticLandingPageCatalog: action.payload.catalog.data,
                programmaticLandingPageSegments: action.payload.segment.data,
            }

        case RESET_PROGRAMMATIC_LANDING_PAGE_GENRE:
            return {
                ...state,
                programmaticLandingPageCatalog: {},
                programmaticLandingPageSegments: [],
            }

        case GENRE_SEGMENT_LIST:
            return {
                ...state,
                genreSegmentList: uniqBy(
                    [
                        ...state.genreSegmentList,
                        ...action.payload.segment.data[0].content,
                    ],
                    'id'
                ),
                programmaticLandingPageCatalog: action.payload.catalog.data,
                genreSegmentTotalCount: parseInt(
                    action.payload.segment.meta.totalCount
                ),
            }

        case RESET_GENRE_SEGMENT_LIST:
            return {
                ...state,
                genreSegmentList: [],
                programmaticLandingPageCatalog: {},
                genreSegmentTotalCount: 0,
            }

        default:
            return state
    }
}

export default genresReducer
