import { Toast } from '@/components/Toast'
import { addParamsToUrl } from '@/helpers/addParamsToUrl'
import { initializeAds } from '@/store/actions/ads'
import { initializeAdsSettings } from '@/store/actions/adsSettings'
import * as adsSelector from '@/store/selectors/ads'
import { getAdSettings } from '@/utils/getAdSettings'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const IMAProvider = ({ children }) => {
    const { data: session } = useSession()

    const dispatch = useDispatch()
    // Ads
    const ads = useSelector(adsSelector._ads)

    useEffect(() => {
        if (!session) return

        let setupAdSettingsInterval = null

        // Clear out interval in case there was a previous interval set
        if (setupAdSettingsInterval) {
            clearInterval(setupAdSettingsInterval)
        }

        // setupAdSettings API Call
        const setupAdSettings = async () => {
            let settings = await getAdSettings(session?.token)

            if (!settings) {
                dispatch(initializeAdsSettings([]))
            } else {
                const modifiedAdsConfig = {}

                settings?.data?.forEach((data) => {
                    const paramsToAdd = {
                        type: 'preroll',
                        stid: data.stationId,
                        // dist:
                        //     process.env.NODE_ENV === 'production'
                        //         ? 'production'
                        //         : 'development',
                        maxAds: data.maxNumberAdsPlaying,
                        'bundle-id': 'noice.web',
                        'store-id': 'noice.web',
                    }

                    const updatedUrl = addParamsToUrl(data.url, paramsToAdd)

                    modifiedAdsConfig[data.entityType] = {
                        ...data,
                        url: updatedUrl,
                    }
                })

                dispatch(initializeAdsSettings(modifiedAdsConfig))
            }
        }

        // Get the ad settings on app load
        setupAdSettings()

        // Get the ad setting after an interval
        setupAdSettingsInterval = setInterval(
            setupAdSettings,
            parseInt(process.env.NEXT_PUBLIC_AD_SETTINGS_INTERVAL) * 60000
        )

        return () => {
            // Cleanup for get Ad Settings Interval
            clearInterval(setupAdSettingsInterval)
        }
    }, [dispatch, session])

    return (
        <>
            {children}
            <Toast
                message={ads?.toastMessage}
                showToast={ads?.adPlayingToast}
                setShowToast={(value) => {
                    dispatch(initializeAds({ adPlayingToast: value }))
                }}
            />

            <video
                src=""
                className="ad-video"
                style={{ display: 'none' }}
            ></video>
            <div className="ad-container" style={{ display: 'none' }}></div>
        </>
    )
}
export default IMAProvider
