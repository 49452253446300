export const INITIALIZE_SUBSCRIPTION_CATALOG_LIST =
    'INITIALIZE_SUBSCRIPTION_CATALOG_LIST'
export const FETCH_SUBSCRIPTION_CATALOG_LIST_STATUS =
    'FETCH_SUBSCRIPTION_CATALOG_LIST_STATUS'
export const RESET_SUBSCRIPTION_CATALOG_LIST = 'RESET_SUBSCRIPTION_CATALOG_LIST'

// Subscription Details

export const INITIALIZE_SUBSCRIPTION = 'INITIALIZE_SUBSCRIPTION'
export const FETCH_SUBSCRIPTION_STATUS = 'FETCH_SUBSCRIPTION_STATUS'
export const RESET_SUBSCRIPTION = 'RESET_SUBSCRIPTION_CATALOG_LIST'
