export const INITIALIZE_USER_PROFILE_PAGE = 'INITIALIZE_USER_PROFILE_PAGE'
export const RESET_USER_PROFILE_PAGE = 'RESET_USER_PROFILE_PAGE'
export const FETCH_USER_PROFILE_STATUS = 'FETCH_USER_PROFILE_STATUS'

export const PROFILETABTYPES = {
    KARYA: 'Karya',
    AKTIFITAS: 'Aktifitas',
}

export const UPDATE_PROFILE_TAB = 'UPDATE_PROFILE_TAB'

export const SET_PROFILE_DETAILS = 'SET_PROFILE_DETAILS'

export const SET_FOLLOWERS = 'SET_FOLLOWERS'

export const SET_FOLLOWING = 'SET_FOLLOWING'

export const RESET_FOLLOWERS_PAGE = 'RESET_FOLLOWERS_PAGE'
export const FETCH_FOLLOWERS_STATUS = 'FETCH_FOLLOWERS_STATUS'

export const RESET_FOLLOWING_PAGE = 'RESET_FOLLOWING_PAGE'
export const FETCH_FOLLOWING_STATUS = 'FETCH_FOLLOWING_STATUS'
export const SET_SHOW_REGISTRATION_PROMPT = 'SET_SHOW_REGISTRATION_PROMPT'

export const SET_USER_WALLET = 'SET_USER_WALLET'

export const SET_USER_WALLET_TRANSACTION = 'SET_USER_WALLET_TRANSACTION'

export const SET_USER_SUBSCRIPTION = 'SET_USER_SUBSCRIPTION'
