import {
    INITIALIZE_TAG,
    UPDATE_TAG_STATUS,
    TAG_DETAIL,
    TAG_DETAIL_STATUS,
    RESET_TAG,
} from '../types/tag'

const initialState = {
    tags: [],
    fetchTagsStatus: 'fetching',
    totalCount: 0,
    tagDetail: {},
    tagDetailStatus: 'fetching',
}

const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_TAG:
            return {
                ...state,
                tags: action.payload,
            }

        case UPDATE_TAG_STATUS:
            return {
                ...state,
                fetchTagsStatus: action.payload,
            }

        case TAG_DETAIL:
            return {
                ...state,
                tagDetail: action.payload,
            }

        case TAG_DETAIL_STATUS:
            return {
                ...state,
                tagDetailStatus: action.payload,
            }
        case RESET_TAG:
            return {
                ...state,
                tags: [],
                fetchTagsStatus: 'fetching',
                totalCount: 0,
                tagDetail: {},
                tagDetailStatus: 'fetching',
            }

        default:
            return state
    }
}

export default tagsReducer
