import {
    INITIALIZE_THEME_PAGE,
    RESET_THEME_PAGE,
    FETCH_THEME_STATUS,
} from '../types/themepage'

const initialState = {
    themepageData: {},
    fetchThemeStatus: 'fetching',
}

const themepageReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_THEME_PAGE:
            return {
                ...state,
                themepageData: action.payload,
            }
        case RESET_THEME_PAGE:
            return {
                ...state,
                themepageData: {},
            }
        case FETCH_THEME_STATUS:
            return {
                ...state,
                fetchThemeStatus: action.payload,
            }

        default:
            return state
    }
}

export default themepageReducer
